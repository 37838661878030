

import { Action } from "redux-saga"

import { IHydraCollection, IOIDCAPI } from "./models/IOIDCAPI"
import { IOIDCProvider } from "./models/IOIDCProvider"
import { IRequestStateAPI } from "./models/request-states"
import { IStorageAPI } from "./models/storage"
import { OIDCStateSelector } from "./redux/reducer"

// #region localStorage keys
/**
 * local storage key name for the auth state
 */
export const AUTH_STATE_KEY = "auth_state"
/**
 * local storage key name for the identifiert for the Proof Key For Code Exchange (PKCE) process
 * @see https://oauth.net/2/pkce/
 */
export const CODE_VERIFIER_KEY = "pkce_code_verifier"
/**
 * local storage key name for a only-once-used key
 * @see https://en.wikipedia.org/wiki/Cryptographic_nonce
 */
export const NONCE_KEY = "nonce"
// #endregion

// #region external configuration object

/**
 * type of an configuration object to configure the OICD module
 */
export interface OIDCConfig<
  AuthType = any,
  ProviderCollection extends IHydraCollection<IOIDCProvider> = IHydraCollection<IOIDCProvider>
> {
  /**
   * URL where the OIDC provider should route the user after authenticating.
   * A page on the local frontend/client that is registered (!) with the provider.
   * Do not change without changing at provider's end simultaneously.
   */
  redirectURL: string
  /**
   * configuration for interactions with the host application backend
   */
  oidcAPI: IOIDCAPI<AuthType, ProviderCollection>
  /**
   * functions for request tracking
   */
  requestStateAPI: IRequestStateAPI
  /**
   * function collection to interact withe local storage
   */
  storageAPI: IStorageAPI

  /** selector to get the OICD state from the applications app state */
  selectOIDCState: OIDCStateSelector
  /** callback function to route the users browser to the given URL */
  routeTo: (url: string) => void
  /** callback function to handle errors emerging in an OICD saga */
  handleSagaError: (sagaName: string, errorMessage: string, action: Action<any>, error: any) => void
  /** callback function to get an error code for an unknown error */
  getUnknownErrorCode: () => string
}

/**
 * Configuration for processing OICD requests.
 * Not exported to not be overwritten unintentionally.
 * Get its value by getOIDCConfig()
 */
let oidcConfig: OIDCConfig

/**
 * to initialize the OICD module with a given config
 */
export const initializeOIDCModule = <
  AuthType = any,
  ProviderCollection extends IHydraCollection<IOIDCProvider> = IHydraCollection<IOIDCProvider>
>(config: OIDCConfig<AuthType, ProviderCollection>): void => {
  oidcConfig = config
}

/**
 * getter to access the OICD config
 * @returns the current OICD config
 */
export const getOIDCConfig = (): OIDCConfig => oidcConfig

// #endregion