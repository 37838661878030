import { all } from "redux-saga/effects"

import { oidcWatcherSaga } from "@modules/oidc/src"
import { challengesWatcherSaga } from "@redux/usecases/challengesForManagement"
import { marketplaceWatcherSaga } from "@redux/usecases/marketplace"
import { onboardingWatcherSaga } from "@redux/usecases/onboarding"
import { projectStateWatcherSaga } from "@redux/usecases/projectState"
import { transitionWatcherSaga } from "@redux/usecases/transition"
import { userAccountWatcherSaga } from "@redux/usecases/userAccount"

import { authWatcherSaga } from "./auth"
import { currentUserWatcherSaga } from "./currentUser"
import { feedbackWatcherSaga } from "./feedback"
import { generalWatcherSaga } from "./generalSaga"
import { ideaWatcherSaga } from "./idea"
import { myProjectsWatcherSaga } from "./myProjects"
import { platformWatcherSaga } from "./platform"
import { processesWatcherSaga } from "./processes"
import { projectWatcherSaga } from "./project"
import { registrationWatcherSaga } from "./registration"
// import { teamUploadsWatcherSaga } from "./teamUploads"
import { usersWatcherSaga } from "./users"
import { verificationWatcherSaga } from "./verification"

// ALL sagas must be registered here!

export function* rootSaga(): any {
  yield all([
    authWatcherSaga(),
    currentUserWatcherSaga(),
    challengesWatcherSaga(),
    feedbackWatcherSaga(),
    generalWatcherSaga(),
    marketplaceWatcherSaga(),
    myProjectsWatcherSaga(),
    ideaWatcherSaga(),
    projectWatcherSaga(),
    onboardingWatcherSaga(),
    platformWatcherSaga(),
    projectStateWatcherSaga(),
    processesWatcherSaga(),
    transitionWatcherSaga(),
    registrationWatcherSaga(),
    // teamUploadsWatcherSaga(),
    userAccountWatcherSaga(),
    usersWatcherSaga(),
    verificationWatcherSaga(),
    oidcWatcherSaga(),
  ])
}

