import { Action } from "redux"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import client from "@api/client"
import { IModel, TransitionInput } from "@api/schema"
import { IFormikActions, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { SubmissionError } from "@services/submissionError"
import { entityTypeFromIModelOrIRI } from "@services/util"

/** @todo multi refactor transition saga? Include maybe locking and unlocking => for details @see https://futureprojects.atlassian.net/browse/FCP-1553 */

/** @†odo multi maybe move to generalSaga */

/** *************************************************************************************************
 * This enum defines the usecases around the "transitions".
 */
export enum TransitionUsecase {
  /**
   * transition entity
   */
  TransitionEntity = "_usecase_transition",
}

// #region generic transition action
interface ITransitionAction<Entity, TransitionState> extends Action {
  actions: IFormikActions
  entity: Entity
  transition: TransitionInput<TransitionState>
  type: TransitionUsecase
}

export const transitionAction = <Entity, TransitionState>(entity: Entity, transition: TransitionInput<TransitionState>, type: TransitionUsecase, actions: IFormikActions): ITransitionAction<Entity, TransitionState> => ({
  actions,
  entity,
  transition,
  type,
})

// #endregion

// #region generic saga
export function* transitionWatcherSaga(): any {
  yield all([
    takeEvery(TransitionUsecase.TransitionEntity, withCallback(transitionSaga)),
  ])
}

function* transitionSaga<Entity extends IModel, TransitionState>(action: ITransitionAction<Entity, TransitionState>) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  const entityType = entityTypeFromIModelOrIRI(action.entity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    const entity: Entity = yield call(client.transitionEntity, action.entity, action.transition)

    yield put(updateModelSuccessAction(entityType, entity))

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, entity))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, entity)
    }

    return entity
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
// #endregion