import { Action } from "redux"

import { IHydraCollection } from "../models/IOIDCAPI"
import { IOIDCProvider } from "../models/IOIDCProvider"


/**
 * list of available reducer action types
 */
export enum OIDCReducerActionTypes {
  SetOIDCProviders = "SET_OIDC_PROVIDERS",
  SetOIDCToken = "SET_OIDC_TOKEN",
  SetPlatformAuthReply = "SET_PLATFORM_AUTH_REPLY"
}

/**
 * abstract type of all OICD reducer actions
 */
interface IOIDCReducerAction extends Action<OIDCReducerActionTypes> {
  type: OIDCReducerActionTypes
}

export interface ISetOIDCProvidersAction extends IOIDCReducerAction {
  oidcProviders: IHydraCollection<IOIDCProvider>
  type: OIDCReducerActionTypes.SetOIDCProviders
}

export interface ISetOIDCTokenAction extends IOIDCReducerAction {
  idToken: string
  type: OIDCReducerActionTypes.SetOIDCToken
}

export interface ISetPlatformAuthReplyAction<AuthType = any> extends IOIDCReducerAction {
  platformAuthReply: AuthType
  type: OIDCReducerActionTypes.SetPlatformAuthReply
}

/**
 * Interface to combine all reducer Actions regarding the OICD process
 */
export type OIDCReducerAction =
  | ISetOIDCProvidersAction
  | ISetOIDCTokenAction
  | ISetPlatformAuthReplyAction

/**
 * Action to store the loaded OIDC provider information
 */
export const setOIDCProvidersAction = (oidcProviders: IHydraCollection<IOIDCProvider>): ISetOIDCProvidersAction => ({
  oidcProviders,
  type: OIDCReducerActionTypes.SetOIDCProviders
})

/**
 * Action to store the fetched OIDC token
 */
export const setOIDCTokenAction = (idToken: string): ISetOIDCTokenAction => ({
  idToken,
  type: OIDCReducerActionTypes.SetOIDCToken
})

/**
 * Action to store the platform's auth reply
 */
export const setPlatformAuthReplyAction = <AuthType = any>(platformAuthReply: AuthType): ISetPlatformAuthReplyAction => ({
  platformAuthReply,
  type: OIDCReducerActionTypes.SetPlatformAuthReply
})

// #region reducer

/**
 * structure of a data set to be used between client and host backend for OICD login process
 */
export interface IOIDCState<AuthType = any> {
  /**
   * list of OICD providers that are supported by the host backend
   */
  oidcProviders: IOIDCProvider[]
  /**
   * OICD id token from the OICD provider to be sent to the host backend
   * to perform token check between host backend and OCID provider
   */
  idToken: string
  /**
   * reply from the host backend when requesting an OICD login
   */
  platformAuthReply: AuthType
}

/**
 * type of a selector function to request the OICDState from the app state
 */
export type OIDCStateSelector = (state: any) => IOIDCState

/**
 * initial value of the OICD provider list
 */
export const OIDC_PROVIDER_DEFAULT_STATE_VALUE: IOIDCProvider[] = []

/**
 * inital OICD state
 */
export const initialOIDCState: IOIDCState = {
  oidcProviders: OIDC_PROVIDER_DEFAULT_STATE_VALUE,
  idToken: undefined,
  platformAuthReply: undefined
}

/**
 * reducer for the OICD state
 */
export const oidcReducer =
  (state: IOIDCState = initialOIDCState, action: IOIDCReducerAction): IOIDCState => {
    switch (action.type) {
      case OIDCReducerActionTypes.SetOIDCProviders:
        const newCollection: IOIDCProvider[] = []
        // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
        const data = (action as ISetOIDCProvidersAction).oidcProviders
        data["hydra:member"].forEach((provider: IOIDCProvider) => {
          newCollection.push(provider)
        })
        console.log("oidcReducer, SetOIDCProviders", newCollection)
        return {
          ...state,
          oidcProviders: newCollection
        }

      case OIDCReducerActionTypes.SetOIDCToken:
        console.log("oidcReducer, SetOIDCToken", (action as ISetOIDCTokenAction).idToken)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          idToken: (action as ISetOIDCTokenAction).idToken
        }

      case OIDCReducerActionTypes.SetPlatformAuthReply:
        console.log("oidcReducer, SetPlatformAuthReply", (action as ISetPlatformAuthReplyAction).platformAuthReply)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          platformAuthReply: (action as ISetPlatformAuthReplyAction).platformAuthReply
        }

      default:
        return state
    }
  }

// #endregion