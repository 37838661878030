/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useState } from "react"
import SVG from "react-inlinesvg"
import { Card, CardHeader } from "reactstrap"
import hasProp from "lodash/has"

import { FAQHelper, FAQKeys, FAQLayer, FAQType } from "./FAQHelper"
import { useSetFAQKey } from "./FAQHooks"
import FAQSearchView from "./FAQSearchView"
import FAQTopicLayer from "./FAQTopicLayer"



interface IProps {
  faqNode: FAQType
  faqKey: string | undefined
}

/**
 * Shows a searchfield in order to search for questions as well as the several areas within the faq-system by given title and image.
 * If the user started a search, found entries are displayed instead of the area titles.
 *
 * This component is used by the FAQMainComponent, as the first/top layer, which will appear to the user on initial request.
 *
 * This component uses TopicLayer as the next layer.
 */

const FAQAreaLayer = ({ faqNode, faqKey }: IProps): JSX.Element => {
  const setFAQKey = useSetFAQKey()

  // SearchResults found?
  const [foundSearchResults, setFoundSearchResults] = useState(false)
  // has the user entered a search-term in the inputfield?
  const [enteredInput, setEnteredInput] = useState(false)

  return FAQHelper.layerToBeShown(faqKey) === FAQLayer.AreaLayer
    // is the given faqKey on AreaLayer-Level?
    ? <div className="faq__area-layer">
      <FAQSearchView
        faqNode={faqNode}
        sendFoundSearchResults={setFoundSearchResults}
        sendEnteredInput={setEnteredInput}
      />
      {// only show Area-Cards, if the user has not entered a search-string or there a no results found
        !foundSearchResults && !enteredInput &&
        <div>
          {Object.keys(faqNode).map((key) => {
            // check and save, if valid picture/title are given
            // picture must be an link to a .svg-file
            const validPicture =
              hasProp(faqNode[key], FAQKeys.Picture)
              && typeof faqNode[key].picture === "string"
              && faqNode[key].picture.endsWith(".svg")

            const validTitle =
              hasProp(faqNode[key], FAQKeys.Title)
              && typeof faqNode[key].title === "string"

            if (validPicture || validTitle) { // show area-card only, if there is a valid title and/or picture
              return <Card
                className="faq__card dancing"
                key={key}
                role="button"
                onClick={() => { setFAQKey(key) }}
                onKeyDown={event => (event.key === "Enter") && setFAQKey(key)}
                tabIndex={0}
              >
                <CardHeader>
                  {validPicture && <SVG
                    src={faqNode[key].picture}
                    title={faqNode[key].title || ""}
                  />}
                  {validTitle && <h2>{faqNode[key].title}</h2>}
                </CardHeader>
              </Card>
            }
          })}
        </div>
      }
    </div>
    // if the given faqKey is not on AreaLayer-level, give it to the next layer
    : <FAQTopicLayer
      faqNode={faqNode}
      faqKey={faqKey}
    />
}
export default FAQAreaLayer