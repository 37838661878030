// Set the environment variables shown below to configure the app.
// FCP_API_ENTRYPOINT, BASE_URL and IMAGE_DOMAINS are required options.
// Via next.config.js most of these options are also exposed to the client side so access is isomorphic.
//
// those constants may be overwritten in tests by Object.defineProperty, but not from within the application

// **********************************************************************************************************
// WARNING: do not import "application" files, since this would possibly lead to circular imports
// that result in a "ReferenceError: Cannot access '...' before initialization".
// Or failing tests with "ReferenceError: window is not defined".
// **********************************************************************************************************
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "@locales/locales-config"

/**
 * Environment variable for the client configuration of SINN.
 * True means, the client configuration of SINN is used.
 */
export const SINN_PROTOTYPE_CLIENT_CONFIG_USED: boolean = process.env.SINN_PROTOTYPE_CLIENT_CONFIG_USED
  ? process.env.SINN_PROTOTYPE_CLIENT_CONFIG_USED.toLowerCase() === 'true'
  : false

/**
 * URL of the backend API
 */
export const FCP_API_ENTRYPOINT: string = process.env.FCP_API_ENTRYPOINT
  ? process.env.FCP_API_ENTRYPOINT
  : process.env.NODE_ENV === 'test'
    ? "https://some-axios-call-was-not-mocked.api.projektfabrik.net" // specific helping URL in test environment for test developer
    : "https://you-have-not-defined-FCP_API_ENTRYPOINT-in-the-env-file.api.projektfabrik.net" // specific helping URL in dev environment

// under which URL is this client running?
// used to generate URLs to send to the API to be sent via email, e.g. for verifications
export const BASE_URL: string = process.env.BASE_URL
  ? process.env.BASE_URL
  : "https://example.projektfabrik.net"

// domains from where images are allowed to load by using Image from 'next/image'
// to be defined in next.config.js
export const IMAGE_DOMAINS: string[] = process.env.IMAGE_DOMAINS
  ? process.env.IMAGE_DOMAINS.split(",").map((d) => { return d.trim() })
  : (process.env.NODE_ENV !== 'production') ? ["localhost",
    process.env.FCP_API_ENTRYPOINT?.replace(/^https?:\/\//, '')]
    : []

// languages offered to the user: subset of languages defined in locales/locales-config.js
export const LANGUAGES_AVAILABLE: string[] = process.env.LANGUAGES_AVAILABLE
  ? process.env.LANGUAGES_AVAILABLE.split(",")
    .map((d) => { return d.trim() })
    // only allow those languages, that are supported by the platform
    .filter(lang => SUPPORTED_LOCALES.includes(lang))
    // add DEFAULT_LOCALE...
    .concat([DEFAULT_LOCALE])
    // ...but filter out duplicates (in case DEFAULT_LOCALE was already given by the environment)
    // we'd like to use this util function but can't since it would result in a circular import (see comment at top of file)
    // .filter(isFirstOccurrenceOfValueInArray)
    .filter((value, index, array) => array.indexOf(value) === index)
  : [DEFAULT_LOCALE]

// cookie name used to store the JWT for page reloads and auto-login in other tabs,
// sameSite=lax/strict (@todo)
export const AUTH_COOKIE_NAME: string = process.env.AUTH_COOKIE_NAME
  ? process.env.AUTH_COOKIE_NAME
  : "fcp.authToken"

// name of the localStorage item that is used to sync the logout between browser tabs
// (setting this only triggers a re-check of the cookie so no other/foreign tab can force-logout the app),
// should be individualized to prevent problems when a user accesses two different FCP instances
export const AUTH_LOCALSTORAGE_NAME: string = process.env.AUTH_LOCALSTORAGE_NAME
  ? process.env.AUTH_LOCALSTORAGE_NAME
  : "fcp.authSync"

// number of seconds before the JWT expires the app will try to refresh is,
// must be below the JWT TTL (defined in the backend: 900 (sec)), should account for network delay and [-3,+3] secs. randomization
export const AUTH_REFRESH_THRESHOLD: number = process.env.AUTH_REFRESH_THRESHOLD
  ? parseInt(process.env.AUTH_REFRESH_THRESHOLD, 10)
  : 15

// number of seconds after which the user is logged out if he wasn't active,
// listens to DOMEvents like mousedown, keydown, ...
export const AUTH_IDLE_TIMEOUT: number = process.env.AUTH_IDLE_TIMEOUT
  ? parseInt(process.env.AUTH_IDLE_TIMEOUT, 10)
  : 3600

// video conferencing for teams
// default: off (false)
export const MODULE_JITSI__SERVICE: boolean = process.env.MODULE_JITSI__SERVICE
  ? process.env.MODULE_JITSI__SERVICE === 'true'
  : false

// number of milliseconds after the project will be reloaded after creating/updating an associated pdf
export const PDF_REFRESH_TIMEOUT = 15000

// number of milliseconds after the app-toast-messages are closed automatically
// is used in src/components/common/ToastContainer.tsx
export const TOAST_AUTO_CLOSE_TIMEOUT = 10000

// is the team-upload available in the platform or not?
// default: no (false)
export const MODULE_TEAM_UPLOAD_AVAILABLE: boolean = process.env.MODULE_TEAM_UPLOAD_AVAILABLE
  ? process.env.MODULE_TEAM_UPLOAD_AVAILABLE.toLowerCase() === 'true'
  : false

// maximum filesize of a single file
// @todo: multimandant: must be fetched from tariff
export const MODULE_TEAM_UPLOAD_MAX_FILESIZE: number = process.env.MODULE_TEAM_UPLOAD_MAX_FILESIZE
  ? parseInt(process.env.MODULE_TEAM_UPLOAD_MAX_FILESIZE, 10)
  : 5

// maximum size of the upload storage
// @todo: multimandant: must be fetched from tariff
export const MODULE_TEAM_UPLOAD_STORAGE_SIZE: number = process.env.MODULE_TEAM_UPLOAD_STORAGE_SIZE
  ? parseInt(process.env.MODULE_TEAM_UPLOAD_STORAGE_SIZE, 10)
  : 50

// is the partner market (SupportRequests) available
// default: no (false)
export const MODULE_PARTNER_MARKET_AVAILABLE: boolean = process.env.MODULE_PARTNER_MARKET_AVAILABLE
  ? process.env.MODULE_PARTNER_MARKET_AVAILABLE.toLowerCase() === 'true'
  : false

// is the export of a project as JSON available?
// default: no (false)
export const MODULE_PROJECT_EXPORT_AVAILABLE: boolean = process.env.MODULE_PROJECT_EXPORT_AVAILABLE
  ? process.env.MODULE_PROJECT_EXPORT_AVAILABLE.toLowerCase() === 'true'
  : false

// switch to switch on/off all function corresponding to challenges
// default: off (false)
export const MODULE_CHALLENGE_AVAILABLE: boolean = process.env.MODULE_CHALLENGE_AVAILABLE
  ? process.env.MODULE_CHALLENGE_AVAILABLE.toLowerCase() === 'true'
  : false

// switch to switch on/off all function corresponding to funds
// default: off (false)
export const MODULE_FUNDS_AVAILABLE: boolean = process.env.MODULE_FUNDS_AVAILABLE
  ? process.env.MODULE_FUNDS_AVAILABLE.toLowerCase() === 'true'
  : false

// switch to switch on/off following functions corresponding to feedback-system
// - the processmanager can create Feedback Invitations (FBI) for the process
// default: on (true)
export const MODULE_FEEDBACK_FOR_PM_AVAILABLE: boolean = process.env.MODULE_FEEDBACK_FOR_PM_AVAILABLE
  ? process.env.MODULE_FEEDBACK_FOR_PM_AVAILABLE.toLowerCase() === 'true'
  : false

// switch to switch on/off following functions corresponding to feedback-system
// - the processmanager can create a discussion from FBI => someone gives feedback to the FBI, so the processmanager answer this feedback(created discussion)
//    - each feedback appears in the feedback dasboard
// - discussion page is available for each project => currently shows all created discussions
// - allowed user can give feedback to a project like the description in a projectprofile
// default: on (true)
export const MODULE_FEEDBACK_FOR_PROJECTS_AVAILABLE: boolean = process.env.MODULE_FEEDBACK_FOR_PROJECTS_AVAILABLE
  ? process.env.MODULE_FEEDBACK_FOR_PROJECTS_AVAILABLE.toLowerCase() === 'true'
  : false


// which file types are available as attachments for proposals?
export const PROPOSAL_ATTACHMENT_FILE_TYPES: string[] = ["pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx", "odt", "ods", "odp", "jpg", "png", "svg"]

// switch to switch on/off all function corresponding to the FAQ
// default: no (false)
export const MODULE_FAQ_AVAILABLE: boolean = process.env.MODULE_FAQ_AVAILABLE
  ? process.env.MODULE_FAQ_AVAILABLE.toLowerCase() === 'true'
  : false


// switch to switch on/off all function corresponding to SDGs
// default: on (true)
export const MODULE_SDG_AVAILABLE: boolean = process.env.MODULE_SDG_AVAILABLE
  ? process.env.MODULE_SDG_AVAILABLE.toLowerCase() === 'true'
  : true

// switch to switch on/off all function corresponding to fields of action
// default: off (false)
export const MODULE_EXTENDED_CATEGORIES_AVAILABLE: boolean = process.env.MODULE_EXTENDED_CATEGORIES_AVAILABLE
  ? process.env.MODULE_EXTENDED_CATEGORIES_AVAILABLE.toLowerCase() === 'true'
  : false


/**
 * is the map for positioning a project available?
 * default: on (true)
 */
export const MODULE_PROJECT_MAP_AVAILABLE = process.env.MODULE_PROJECT_MAP_AVAILABLE
  ? process.env.MODULE_PROJECT_MAP_AVAILABLE.toLowerCase() === 'true'
  : true

/**
 * api key for map
 * to be used bei the map module
 */
export const API_KEY_MAPTILER = process.env.API_KEY_MAPTILER
  ? process.env.API_KEY_MAPTILER
  : null

// switch for the Login-Auth-Timer, to be shown in the header
// to check if login-session is timed correctly
// default: no (false)
export const MODULE_LOGOUT_TIMER_AVAILABLE: boolean = process.env.MODULE_LOGOUT_TIMER_AVAILABLE
  ? process.env.MODULE_LOGOUT_TIMER_AVAILABLE.toLowerCase() === 'true'
  : false

/**
 * OAuth provider module: module to use external authentication providers
 */
export const MODULE_OIDC_AVAILABLE: boolean = process.env.MODULE_OIDC_AVAILABLE
  ? process.env.MODULE_OIDC_AVAILABLE.toLowerCase() === 'true'
  : false

// should the project card have the project picture?
export const MODULE_PICTURE_IN_PROJECT_CARD: boolean = process.env.MODULE_PICTURE_IN_PROJECT_CARD
  ? process.env.MODULE_PICTURE_IN_PROJECT_CARD.toLowerCase() === 'true'
  : false

/** should the marketplace not be public but for logged in users only */
export const SETTING_MARKETPLACE_FOR_LOGGED_IN_ONLY: boolean = process.env.SETTING_MARKETPLACE_FOR_LOGGED_IN_ONLY
  ? process.env.SETTING_MARKETPLACE_FOR_LOGGED_IN_ONLY.toLowerCase() === 'true'
  : false

/**
 * Common password that users must use when registering to the platform.
 * Default: no password necessary
 */
export const SETTING_REGISTER_PLATFORM_PASSWORD: string = process.env.SETTING_REGISTER_PLATFORM_PASSWORD
  ? process.env.SETTING_REGISTER_PLATFORM_PASSWORD
  : null

// #region external services/API


// SENTRY-Variables for logging to sentry
// used and set in sentry.client.config.js
export const SENTRY_DSN: string = process.env.SENTRY_DSN
export const SENTRY_ORG: string = process.env.SENTRY_ORG
export const SENTRY_PROJECT: string = process.env.SENTRY_PROJECT
export const SENTRY_TRACE_SAMPLE_RATE: number = parseInt(process.env.SENTRY_TRACE_SAMPLE_RATE, 10) || 0

// DEEPL API key
export const API_KEY_DEEPL: string = process.env.API_KEY_DEEPL
  ? process.env.API_KEY_DEEPL
  : null

// #endregion

// german data protection issue:
// for municipalities the legal basis for data protection depends on the federal state
// for non-municipalities another legal basis is relevant
// based on ISO 3166: https://de.wikipedia.org/wiki/ISO_3166-2:DE
export enum LegalBasis {
  DEBW = "DE-BW",
  DEBY = "DE-BY",
  DEBE = "DE-BE",
  DEBB = "DE-BB",
  DEHB = "DE-HB",
  DEHH = "DE-HH",
  DEHE = "DE-HE",
  DEMV = "DE-MV",
  DENI = "DE-NI",
  DENW = "DE-NW",
  DERP = "DE-RP",
  DESL = "DE-SL",
  DESN = "DE-SN",
  DEST = "DE-ST",
  DESH = "DE-SH",
  DETH = "DE-TH",
  // non-municipalities
  Organisation = "organisation",
}

// legal basis (Rechtsgrundlage) for data processing/Datenschutz
// use of the 16 federal states or "organisation" by default
// @todo: load/store legal basis from process/program/mandant
export const LEGAL_BASIS: string = process.env.LEGAL_BASIS && (Object as any).values(LegalBasis).includes(process.env.LEGAL_BASIS)
  ? process.env.LEGAL_BASIS
  : LegalBasis.Organisation

export const DEBUG_LOG_UNTRANSLATED_I18N_KEYS: boolean = process.env.DEBUG_LOG_UNTRANSLATED_I18N_KEYS
  ? process.env.DEBUG_LOG_UNTRANSLATED_I18N_KEYS.toLowerCase() === 'true'
  : false

/**
 * Debug env var to switch off console logs on missing API mocks
 * to be disabled in single tests. By default missing API mocks are logged to the console in test mode
 * but sometimes you want to test an unmocked situation but explicitely do not want to have a console
 * warning.
 */
export const DEBUG_LOG_MISSING_API_MOCKS: boolean = process.env.DEBUG_LOG_MISSING_API_MOCKS
  ? process.env.DEBUG_LOG_MISSING_API_MOCKS.toLowerCase() === 'true'
  : true

/**
 * Temporary constant for development with SINN staging server
 *
 * @todo oauth remove
 */
export const BASIC_AUTH_SINN: string = process.env.BASIC_AUTH_SINN