import Link from "next/link"
import { I18nProviderProps } from "next-translate"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer as WrappedTC, toast } from "react-toastify"

import { DynamicNamespaces, LoadedI18nNamespacesContext } from "@components/hoc/DynamicNamespaces"
import { removeNotificationAction } from "@redux/actions/notifications"
import { AppState } from "@redux/reducer"
import { INotification, namespacesFromNotificationState } from "@redux/reducer/notifications"
import { fallbackStringWhenLoading, useDynamicTranslation } from "@services/i18n"
import { runsInBrowser } from "@services/util"
import { TOAST_AUTO_CLOSE_TIMEOUT } from "config"

// NOTE see /doc/principles/i18n.md for notes on why this is structured as it is

const TIMEOUT_LENGTH = 400 // see FCP-1213

const TranslatedToastContainer = () => {
  const t = useDynamicTranslation()
  const dispatch = useDispatch()
  const notifications = useSelector((state: AppState) => state.notifications)
  const loadedI18nNamespaces = useContext(LoadedI18nNamespacesContext)

  useEffect(() => {
    // check is probably unnecessary, no sagas run server-side, no notifications should be created
    if (!runsInBrowser()) {
      return
    }

    notifications.forEach((notification: INotification) => {

      // check if namespaces for this notification have been loaded - true if all required namespace objects are available in context
      const nsLoaded = namespacesFromNotificationState([notification])
        .map(ns => loadedI18nNamespaces?.[ns])
        .filter(missing => !missing)
        .length === 0

      if (!nsLoaded) {
        return
      }

      // @todo for some reason the toast does not show at all when we don't use `setTimeout`
      // (as opposed to previous behaviour when it showed, but with the i18n key instead of translated text)
      // see FCP-1213
      setTimeout(() =>
        toast(
          // NOTE we pass `null` as namespace for t(), b/c we don't know the namespace and we assume it's in the key
          typeof (notification.content) === "string"
            ? t(null, notification.content, notification.params)
            // if content is not a string, it's an object that defines a notification with button-link
            : <>
              <p>
                {t(null, notification.content.messageKey, notification.content.messageParams)}
              </p>
              <Link href={notification.content.route} className="btn btn-primary">
                {t(null, notification.content.linkTitleKey, notification.content.linkTitleParams)}
              </Link>
            </>
          , notification.options
        ),
        TIMEOUT_LENGTH
      )

      dispatch(removeNotificationAction(notification.id))
    })
  }, [notifications
    , loadedI18nNamespaces
  ])

  return (
    <WrappedTC autoClose={TOAST_AUTO_CLOSE_TIMEOUT} />
  )
}

/**
 * Wrap the react-toastify container to get our notifications from the redux store
 * and translate them before displaying, as redux(-saga) has no access to the translation.
 * toasts are little messages to the user, explained here: https://ichi.pro/de/machen-sie-ihre-reactjs-mit-toastify-benutzerfreundlich-119594772770955
 *
 */
const ToastContainer = (): React.FunctionComponentElement<I18nProviderProps> | React.ReactNode => {
  const notifications = useSelector((state: AppState) => state.notifications)
  // state to store calculated namespacePaths from notifications
  const [namespacePaths, setNamespacePaths] = useState([])

  useEffect(() => {
    // ToastContainer may be displayed on any page and must be able to show notification(s) from any usecase/entity/namespace.
    // The namespace(shortcut)s of those texts are stored in the according fields in out `AppState`'s `notification` (fully qualified i18n keys).
    // To be able to translate those keys, we need to load exactly those namespaces, using the namespaceShortcuts from the props fields.
    // () => namespacesFromNotificationState(useStore().getState().notifications)
    // NOTE that namespacesFromNotificationState returns the true paths, not the shortcuts
    setNamespacePaths(namespacesFromNotificationState(notifications))
  }, [notifications])

  return <DynamicNamespaces
    namespaces={namespacePaths}
    fallback={fallbackStringWhenLoading}
  >
    <TranslatedToastContainer />
  </DynamicNamespaces>
}

export default ToastContainer

