import axios, { AxiosRequestConfig } from "axios"
import { Action } from "redux"
import { all, call, takeLatest, put } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import { BASIC_AUTH_SINN } from "config"

import { setOIDCProvidersAction, setOIDCTokenAction, setPlatformAuthReplyAction } from "./reducer"
import { getOIDCConfig } from "../config"
import { IHydraCollection } from "../models/IOIDCAPI"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { clearOIDCItemsFromStorage } from "../utils/util"


/**
 * Types of actions and corresponding sagas to interact with the host application backend
 * and the Open ID provider.
 */
export enum OIDCSagaActionTypes {
  LoadOIDCProviders = "LOAD_OIDC_PROVIDERS",
  FetchOIDCToken = "FETCH_OIDC_TOKEN",
  LoginWithOIDCToken = "LOGIN_WITH_OIDC_TOKEN",
}

/**
 * Interface to combine all open id saga triggering Actions
 */
export type OIDCSagaAction =
  | ILoadOIDCProvidersAction
  | IFetchOIDCTokenAction
  | ILoginWithOIDCTokenAction


/**
 * interface of actions the trigger a saga within the Open ID context
 */
interface IOIDCSagaAction extends Action {
  type: OIDCSagaActionTypes
}

/**
 * interface to trigger the loading of a list of supported Open ID providers.
 */
export interface ILoadOIDCProvidersAction extends IOIDCSagaAction {
  type: OIDCSagaActionTypes.LoadOIDCProviders
}

/**
 * Action to trigger the saga to fetch the Open ID token from the Open ID provider.
 */
export interface IFetchOIDCTokenAction extends IOIDCSagaAction {
  provider: IOIDCProvider
  authCode: string
  codeVerifier: string
  type: OIDCSagaActionTypes.FetchOIDCToken
}

/**
 * Action to trigger the login at the host application
 * by using an Open ID token.
 */
export interface ILoginWithOIDCTokenAction extends IOIDCSagaAction {
  /** Open ID provider from where the user has an Open ID token and wants to log in into the host application */
  provider: IOIDCProvider
  /** Open ID token that was got from the Open ID provider after the user authenticated there */
  idToken: string
  type: OIDCSagaActionTypes.LoginWithOIDCToken
}


/**
 * Action to load OIDC provider information of the host application
 */
export const loadOIDCProvidersAction = (): ILoadOIDCProvidersAction => ({
  type: OIDCSagaActionTypes.LoadOIDCProviders
})

/**
 * Action to fetch the OIDC token from the provider (calls OIDC-Provider's endpoint instead of the host application's backend)
 */
export const fetchOIDCTokenAction = (provider: IOIDCProvider, authCode: string, codeVerifier: string): IFetchOIDCTokenAction => ({
  provider,
  authCode,
  codeVerifier,
  type: OIDCSagaActionTypes.FetchOIDCToken
})

/**
 * Action to login on the host application with an OIDC token
 */
export const loginWithOIDCTokenAction = (provider: IOIDCProvider, idToken: string): ILoginWithOIDCTokenAction => ({
  provider,
  idToken,
  type: OIDCSagaActionTypes.LoginWithOIDCToken
})

// #region sagas

export function* oidcWatcherSaga(): any {
  yield all([
    takeLatest(OIDCSagaActionTypes.LoadOIDCProviders, withCallback(loadOIDCProvidersSaga)),
    takeLatest(OIDCSagaActionTypes.FetchOIDCToken, withCallback(fetchOIDCTokenSaga)),
    takeLatest(OIDCSagaActionTypes.LoginWithOIDCToken, withCallback(loginWithOIDCTokenSaga)),
  ])
}

/**
 * Loads supported providers from the host application backend.
 */
export function* loadOIDCProvidersSaga(action: ILoadOIDCProvidersAction): Generator<any, IHydraCollection<IOIDCProvider>, any> {
  try {

    console.log("loadOIDCProvidersSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoadOIDCProviders))

    const oidcProviders: IHydraCollection<IOIDCProvider> = yield call(getOIDCConfig().oidcAPI.getOIDCProviders)

    console.log("loadOIDCProvidersSaga: oidcProviders", oidcProviders)

    yield put(setOIDCProvidersAction(oidcProviders))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoadOIDCProviders))

    return oidcProviders
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("loadOIDCProvidersSaga", errorMessage, null, err)

    console.log("loadOIDCProvidersSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoadOIDCProviders, errorMessage))

    yield put(setOIDCProvidersAction(null))

    return null
  }
}

/**
 * Saga to fetch an Open ID token from an Open ID provider
 */
export function* fetchOIDCTokenSaga(action: IFetchOIDCTokenAction): Generator<any, string, any> {
  try {

    console.log("fetchOIDCTokenSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.FetchOIDCToken))

    /**
     * params to be added to the API request to the OpenID provider
     *
     * NOTE: the param names are standardizes for interaction the OpenID provider
     */
    const urlParams = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: action.provider.clientId,
      code: action.authCode,
      redirect_uri: getOIDCConfig().redirectURL,
      code_verifier: action.codeVerifier
    })

    console.log("fetchOIDCTokenSaga: urlParams", urlParams)

    let requestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    // #region add BasicAuth to request config, if required
    if (BASIC_AUTH_SINN) {
      const [basicAuthUser, basicAuthPassword] = Buffer
        .from(BASIC_AUTH_SINN, "base64")
        .toString().split(":")
      // console.log("fetchOIDCTokenSaga: basicAuthUser, basicAuthPassword", basicAuthUser, basicAuthPassword)

      requestConfig = {
        ...requestConfig,
        auth: {
          username: basicAuthUser,
          password: basicAuthPassword
        }
      }
    }
    // #endregion

    console.log("fetchOIDCTokenSaga: requestConfig", requestConfig)

    // @todo oauth how do we get rid of "unbound method" error, when at the same time calling apiClient's methods
    // and getOIDCConfig().getOIDCProviders works without error?
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const response = yield call(axios.post, action.provider.tokenUrl, urlParams, requestConfig)

    console.log("fetchOIDCTokenSaga: response", response)

    const { id_token: idToken } = response.data

    yield put(setOIDCTokenAction(idToken))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.FetchOIDCToken))

    return idToken as string
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("fetchOIDCTokenSaga", errorMessage, null, err)

    console.log("fetchOIDCTokenSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.FetchOIDCToken, errorMessage))

    yield put(setOIDCTokenAction(null))
    return null
  }
}


export function* loginWithOIDCTokenSaga<AuthType = any>(action: ILoginWithOIDCTokenAction): Generator<any, AuthType, any> {
  try {

    console.log("loginWithOIDCTokenSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoginWithOIDCToken))

    const platformAuthReply = yield call(getOIDCConfig().oidcAPI.loginWithOIDCToken, action.provider.shortName, action.idToken) as AuthType

    console.log("loginWithOIDCTokenSaga: platformAuthReply", platformAuthReply)

    yield put(setPlatformAuthReplyAction(platformAuthReply))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoginWithOIDCToken))

    // We won't need the stored items no more.
    // NOTE this could also be done in an onSuccess-callback provided via action (e.g. from the calling hook),
    // or maybe by using some requestState-interpretation (also in the calling hook).
    clearOIDCItemsFromStorage()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return platformAuthReply
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("loginWithOIDCTokenSaga", errorMessage, null, err)

    console.log("loginWithOIDCTokenSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoginWithOIDCToken, errorMessage))

    yield put(setPlatformAuthReplyAction(null))
    return null
  }
}

// #endregion