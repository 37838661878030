import { FieldConfig, FieldMetaProps, FieldProps, FormikErrors } from "formik"
import { I18n } from "next-translate"
import React, { ReactElement } from "react"
import { FormText } from "reactstrap"

import { addNamespacePrefixIfNotPresent } from "@services/i18n"
import { stringAsHtmlId } from "@services/util"

import { IFAQPopupProps } from "../faq/FAQPopup"
import HtmlContent from "../HtmlContent"
import TranslatedHtml from "../TranslatedHtml"

/**
 * returns an ID for fields with error
 */
export const errorIdForField = (fieldName: string): string => "error_on_field_" + stringAsHtmlId(fieldName)

/** Formik setFieldValue has this specific type, e.g. the FormType could be IProgram */
export type SetFieldValueType<FormType = any> = (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormType>>

export type FieldComponentType = FieldConfig["component"]

export interface IBaseFormikProps extends FieldProps {
  help?: string
  i18n: I18n
  id?: string
  label?: string
  name: string
  required?: boolean
  translateHelp?: boolean
  translateLabel?: boolean
  role?: string
  /*
 * NOTE: there is no type checking for this prop if a Formik component with FAQ popup is "embedded"
 * in way of <Field component={...} (instead of using <FAQPopup ...> directly).
 * In this cases coders should use explicit typing with `as IFAQPopupProps`.
 * See doc/principles/forms.md for more information as well as https://futureprojects.atlassian.net/browse/FCP-1125
 */
  faqpopup?: IFAQPopupProps
}
/**
 * Offers a standard for showing errors, help and a label.
 *
 * If the field is required and the label is not empty adding an asterisk at the of the label
 */
abstract class FormikElement<Props extends IBaseFormikProps> extends React.Component<Props> {
  protected meta = (): FieldMetaProps<unknown> => this.props.form.getFieldMeta(this.props.field.name)
  protected hasError = (): boolean => this.meta().error && typeof this.meta().error === "string"

  protected labelText(): string {
    // render no <label> when an empty label was provided,
    // if no label was given fallback to the element name
    let label = typeof this.props.label === "undefined"
      ? this.props.field.name.charAt(0).toUpperCase() + this.props.field.name.slice(1)
      : this.props.label

    // translate now, maybe a translation string was given but the translation was empty
    // -> don't show
    if (label.length > 0
      // if the given label was already translated or should not be translated because it is a
      // user-entered string: don't try to translate, this will possibly strip some text if the
      // string contains ":" or "." (i18n-next keySeparator, nsSeparator)
      && (typeof this.props.translateLabel === "undefined" || this.props.translateLabel === true)
    ) {
      label = this.props.i18n.t(label)
    }

    if (label.length > 0 && this.props.required) {
      label = label + " *"
    }

    return label
  }

  protected errorElement = (): ReactElement => {
    return this.hasError() && <div
      id={errorIdForField(this.props.name || this.props.field.name)}
      className="invalid-form-input text-danger was-validated"
    >
      <TranslatedHtml content={addNamespacePrefixIfNotPresent("error", this.meta().error)} />
    </div>
  }

  protected helpElement = (): ReactElement => {
    let helpText = this.props.help
    if (typeof helpText === "undefined" || !helpText.length) {
      return null
    }

    // if the given help was already translated or should not be translated because it is a
    // user-entered string: don't try to translate, this will possibly strip some text if the
    // string contains ":" or "." (i18n-next keySeparator, nsSeparator)
    if (typeof this.props.translateHelp === "undefined" || this.props.translateHelp === true) {
      helpText = this.props.i18n.t(helpText)
    }

    // check length again, maybe the translation is empty
    return helpText.length
      ? <FormText>
        <HtmlContent content={helpText} />
      </FormText>
      : null
  }

}

export default FormikElement
