import { Action } from "redux"

import { ISysinfo } from "@api/schema"
import { AppState } from "@redux/reducer"


/*
* Actions especially for general purposes for the application itself.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum PlatformActionTypes {
  ClearStore = "CLEAR_STORE",
  /** for resetting the store with a given data payload */
  Hydrate = "HYDRATE",

  LoadBackendCommit = "LOAD_BACKEND_COMMIT",
  SetBackendCommit = "SET_BACKEND_COMMIT",

  LoadSysinfo = "LOAD_SYS_INFO",
  SetSysinfo = "SET_SYS_INFO",

  SetAuthTTL = "SET_AUTH_TTL",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IPlatformAction extends Action {
  type: PlatformActionTypes
}

export interface IClearStorageAction extends IPlatformAction {
  type: PlatformActionTypes.ClearStore
}

export interface IHydrateAction extends IPlatformAction {
  payload: AppState
  type: PlatformActionTypes.Hydrate
}

export interface ILoadBackendCommitAction extends IPlatformAction {
  type: PlatformActionTypes.LoadBackendCommit
}

export interface ISetBackendCommitAction extends IPlatformAction {
  hash: string
  type: PlatformActionTypes.SetBackendCommit
}

export interface ILoadSysinfoAction extends IPlatformAction {
  type: PlatformActionTypes.LoadSysinfo
}

export interface ISetSysinfoAction extends IPlatformAction {
  sysinfo: ISysinfo
  type: PlatformActionTypes.SetSysinfo
}

export interface ISetAuthTTLAction extends IPlatformAction {
  ttl: number
  type: PlatformActionTypes.SetAuthTTL
}

/**
 * Interface to combine all Actions
 */
export type PlatformAction =
  | IClearStorageAction
  | IHydrateAction
  | ILoadBackendCommitAction
  | ISetBackendCommitAction
  | ILoadSysinfoAction
  | ISetSysinfoAction
  | ISetAuthTTLAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */


export const clearStoreAction = (): IClearStorageAction => ({
  type: PlatformActionTypes.ClearStore
})

/**
 * Action for initialising the state or overwrite parts of an existing state by a given payload
 */
export const hydrateAction = (payload: AppState): IHydrateAction => ({
  payload,
  type: PlatformActionTypes.Hydrate
})

/**
 * Action to load the current backend commit
 */
export const loadBackendCommitAction = (): ILoadBackendCommitAction => ({
  type: PlatformActionTypes.LoadBackendCommit
})

/**
 * Action to store the loaded backend commit
 */
export const setBackendCommitAction = (hash: string): ISetBackendCommitAction => ({
  hash,
  type: PlatformActionTypes.SetBackendCommit
})


/**
 * Action to load system information about the platform
 */
export const loadSysinfoAction = (): ILoadSysinfoAction => ({
  type: PlatformActionTypes.LoadSysinfo
})

/**
 * Action to store the loaded system information
 */
export const setSysinfoAction = (sysinfo: ISysinfo): ISetSysinfoAction => ({
  sysinfo,
  type: PlatformActionTypes.SetSysinfo
})


/**
 * Action to store the remaining time the current authentification of the user has (TTL = time to live)
 */
export const setAuthTTLAction = (ttl: number): ISetAuthTTLAction => ({
  ttl,
  type: PlatformActionTypes.SetAuthTTL
})