import { ISysinfo } from "@api/schema"
import { PlatformAction, PlatformActionTypes } from "@redux/actions/platform"

export interface IPlatformState {
  authTTL: number
  backendCommit: string
  sysinfo?: ISysinfo
}

export const initialPlatformState: IPlatformState = {
  authTTL: 0,
  backendCommit: '',
  sysinfo: undefined
}

export const platformReducer =
  (state: IPlatformState = initialPlatformState, action: PlatformAction): IPlatformState => {
    switch (action.type) {
      case PlatformActionTypes.SetBackendCommit:
        return {
          ...state,
          backendCommit: action.hash
        }

      case PlatformActionTypes.SetSysinfo:
        return {
          ...state,
          sysinfo: action.sysinfo
        }

      case PlatformActionTypes.SetAuthTTL:
        return {
          ...state,
          authTTL: action.ttl
        }

      default:
        return state
    }
  }
