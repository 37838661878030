

import { IIdea, IProject, IProjectFollowership, IRI, IUser, IUserProjectRole, MembershipRole } from "@api/schema"
import { AppState } from "@redux/reducer"
import { selectCurrentUser } from "@redux/reducer/auth"
import { selectCollection, selectProjectBySlugOrId } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { selectMyUserObjectRoles } from "@redux/usecases/userObjectRoles"
import { INNER_TEAM_ROLES, TEAM_ROLES } from "@services/hooks/usePageAccessCheck"
import { filterRoles } from "@services/userObjectRolesHelper"
import { idFromIModelOrIRI, iriFromEntityTypeAndId, iriFromIModelOrIRI } from "@services/util"


/**
 * @returns a list of UserProjectRoles of the current user taken from the state
 */
export const selectMyMemberships = (state: AppState): IUserProjectRole[] =>
  filterRoles<IUserProjectRole>(selectMyUserObjectRoles(state), TEAM_ROLES)


/**
 *
 * @param state
 * @returns
 * @deprecated
 */
export const selectMyFollowerships = (state: AppState): IProjectFollowership[] => {
  const user = selectCurrentUser(state)
  return user ? user.followerships : null
}

/**
 * Selector to retrieve the list of ideas the user created
 *
 * @param state
 * @returns null if the user wasn't loaded yet, else the list of ideas (may be empty)
 * @deprecated
 */
export const selectMyCreatedIdeas = (state: AppState): IIdea[] => {
  const user: IUser = selectCurrentUser(state)

  // to allow differentiating between "user not loaded" and "has no projects":
  if (!user) {
    return null
  }

  return selectCollection<IIdea>(state, EntityType.Idea).filter(
    idea => iriFromIModelOrIRI(idea.createdBy) === iriFromIModelOrIRI(user))
}

/**
 * Selector to retrieve the list of project IDs the user is a member (or applicant) of.
 *
 * @param state
 * @returns null if the user wasn't loaded yet, else the list of IDs (may be empty)
 */
export const selectMyProjectIDs = (state: AppState): number[] => {
  const memberships = selectMyMemberships(state)

  // to allow differentiating between "user not loaded" and "has no memberships":
  if (memberships === null) {
    return null
  }
  if (memberships.length === 0) {
    return []
  }

  return memberships.map((membership) => idFromIModelOrIRI(membership.object))
}


/**
 * Selects the memberrole within the project with the given projectId
 *
 * @param state
 * @param projectId
 * @returns null, if the user has no membership within this project or the project could not be found, other returns the membership role
 */
export const selectCurrentProjectMemberRole = (state: AppState, projectId: number): MembershipRole => {
  const memberships = selectMyMemberships(state)
  if (!memberships || memberships.length === 0 || !projectId) {
    return null
  }

  const projectMembership = memberships
    .filter(upr => upr.object.id === projectId)
    .shift()

  return projectMembership ? projectMembership["@type"] : null
}

/**
 * Checks, if the user has a role within the project
 *
 * @param state
 * @param projectId id of the project
 * @returns true, if the user is a member of the project
 */
export const isProjectMember = (state: AppState, projectId: number): boolean =>
  selectCurrentProjectMemberRole(state, projectId) !== null

/**
 * Checks, if the user is a Coordinator or a Planner of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is part of the INNER TEAM of the project
 */
export const isProjectEditor = (state: AppState, projectID: number): boolean => {
  if (!projectID) {
    return false
  }

  const projectRoles = selectMyMemberships(state)

  return filterRoles<IUserProjectRole>(projectRoles, INNER_TEAM_ROLES).length !== 0
}

/**
 * Checks, if the user has the specific membershipRole within the project
 *
 * @param membershipRole
 * @param state
 * @param projectId id of the project
 * @returns true, if the user has the given membershipRole in the project
 */
export const hasMembershipRole = (membershipRole: MembershipRole, state: AppState, projectId: number): boolean =>
  selectCurrentProjectMemberRole(state, projectId) === membershipRole

/**
 * Checks, if the given user is a follower of the project
 *
 * @todo multi unused => delete this function?
 *
 * @param state
 * @param projectID
 * @param user the user to be checked
 * @returns true, if the user is an follower of the project
 * @deprecated
 */
export const isProjectFollower = (state: AppState, projectID: number, user: IUser | IRI): boolean =>
  !!selectProjectBySlugOrId(state, projectID.toString()).followerships.find(ship =>
    iriFromIModelOrIRI(ship.project) === iriFromEntityTypeAndId(EntityType.Project, projectID)
    && iriFromIModelOrIRI(ship.user) === iriFromIModelOrIRI(user))


/**
 * Select projects, which have certain proposal. The collection should currently always have one project.
 *
 * @param state
 * @param proposalIRI
 * @returns a collection with one project
 */
export const selectProjectByProposalIri = (state: AppState, proposalIRI: string): IProject =>
  selectCollection<IProject>(state, EntityType.Project)
    .find(p => p.proposals.find(a => a["@id"] === proposalIRI))