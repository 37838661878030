import { useCallback, useEffect, useState } from "react"
import { EVENTS, useIdleTimer } from "react-idle-timer"
import { ConnectedProps, connect } from "react-redux"
import ReactTimeout, { ReactTimeoutProps } from "react-timeout"
import { Action, Dispatch } from "redux"

import { initAuthAction, userIsIdleAction } from "@redux/actions/auth"
import { setAuthTTLAction } from "@redux/actions/platform"
import { AppState } from "@redux/reducer"
import { selectIsAuthInitialized } from "@redux/reducer/auth"
import { runsInBrowser } from "@services/util"
import { AUTH_IDLE_TIMEOUT, AUTH_LOCALSTORAGE_NAME, MODULE_LOGOUT_TIMER_AVAILABLE } from "config"

// this helper helps to observe, if the user still uses the application

const mapStateToProps = (state: AppState) => ({
  isAuthInitialized: selectIsAuthInitialized(state),
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  isIdle: () => dispatch(userIsIdleAction()),
  initAuth: () => dispatch(initAuthAction()),
  setAuthTTL: (ttl: number) => dispatch(setAuthTTLAction(ttl)), // @todo: eslint-error
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>

// this is extracted from the App as it cannot use react-redux's connect() because it provides the
// redux store only to subcomponents of App using <Provider>
const AppHelper: React.FC<Props & ReactTimeoutProps> = (props: Props & ReactTimeoutProps) => {
  const [authSync, setAuthSync] = useState("")

  /**
   * We listen to changes to the browsers localStorage to login/logout across multiple tabs.
   * To prevent XSS we don't directly trigger a login/logout but re-check the auth cookie, which
   * is safe because it's same-site only
   */
  const syncLocalAuth = useCallback((event: { key: string; newValue: string }) => {
    if (event.key !== AUTH_LOCALSTORAGE_NAME) {
      return
    }

    // this will be a dateString, compare here to make sure it's not our own update
    if (event.newValue === authSync) {
      return
    }

    setAuthSync(event.newValue)
    props.initAuth()
  }, [])

  // Default events include mouseMove, touchstart, MSPointerMove - we don't count this as "activity"
  const activeEvents: EVENTS[] = ["click", "dblclick", "DOMMouseScroll", "dragstart", "drop", "keydown", "mousewheel", "mousedown", 'MSPointerDown', "touchmove", "touchstart", "wheel"]

  // the IdleTimer keeps track of user actions (client-side) and triggers a logout after the
  // configured number of seconds.
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRemainingTime, reset } = useIdleTimer({
    crossTab: {
      emitOnAllTabs: true,

      // @odo individualize further, e.g. by using SENTRY_ENVIRONMENT so multiple instances
      // of FCP dont interact with each other
      channelName: `fcp-idle`,
    },
    events: activeEvents,
    onIdle: props.isIdle,
    timeout: AUTH_IDLE_TIMEOUT * 1000,

    // hack: activity on one tab does not reset the time on other tabs,
    // but reset events are synced -> trigger reset of shown timeout-time manually
    // @see https://github.com/SupremeTechnopriest/react-idle-timer/issues/177
    onAction: MODULE_LOGOUT_TIMER_AVAILABLE ? () => { reset() } : undefined,
    debounce: 1000,
  })

  // called from the setInterval to inform the redux store about the
  // time till inactivity
  const onTimer = () => {
    props.setAuthTTL(getRemainingTime())
  }

  useEffect(() => {
    // the checks for "running in a browser" (type window !== "undefined") and not initialized are only fail-safes, this hook
    // should already only be executed once and not server-side:
    // trigger checking the cookie for a previous auth (after refresh via F5 or browser restart)
    if (runsInBrowser() && !props.isAuthInitialized) {
      props.initAuth()
    }

    if (MODULE_LOGOUT_TIMER_AVAILABLE) {
      props.setInterval(onTimer, 1000)
    }

    window.addEventListener("storage", syncLocalAuth)
    return () => {
      window.removeEventListener("storage", syncLocalAuth)
    }
  }, [/* empty array as second parameter prevents the hook from being called on each page navigation */])

  return <></>
}

export default connector(ReactTimeout(AppHelper))
