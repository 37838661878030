import { IProject, IUserObjectRole, IUserProjectRole } from "@api/schema"
import { AppState } from "@redux/reducer"
import { selectCurrentUserId } from "@redux/reducer/auth"
import { selectCollectionUsecaseState } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"
import { TEAM_ROLES } from "@services/hooks/usePageAccessCheck"
import { filterRoles } from "@services/userObjectRolesHelper"
import { idFromIModelOrIRI, iriFromEntityTypeAndId, stringToInt } from "@services/util"


// @todo multi: die hiesigen Funktionen könnten in den userObjectRolesHelper wandern

/**
 * gets all UserObjectRoles of the user with the given id
 * from the request that fetched the UORs from the users endpoint
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectUserObjectRoles = (state: AppState, userId: number): IUserObjectRole[] => {
  if (!userId) {
    return []
  }

  const userObjectRoles: IUserProjectRole[] = selectCollectionUsecaseState(
    state,
    EntityType.UserObjectRole,
    usecaseKeyForLoadCollection(null, null, iriFromEntityTypeAndId(EntityType.User, userId))
  )
    .getItems<IUserProjectRole>()

  return userObjectRoles
}

/**
 * gets all UserObjectRoles of the currentUser from the state
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectMyUserObjectRoles = (state: AppState): IUserObjectRole[] =>
  selectUserObjectRoles(state, selectCurrentUserId(state))

/**
 * gets all UserObjectRoles of the currentUser from the state, which match one of the MembershipRoles
 * to get all project memberships of the user
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectMyMemberships = (state: AppState): IUserProjectRole[] => {
  const userId = selectCurrentUserId(state)
  if (!userId) {
    return []
  }

  const userProjectRoles: IUserProjectRole[] =
    filterRoles<IUserProjectRole>(selectMyUserObjectRoles(state), TEAM_ROLES)

  return userProjectRoles
}


/**
 * Selects this project from the user's UserObjectRoles that has the given slug or id.
 *
 * NOTE: projects from UserObjectRoles are stubs only!
 *
 * @param state the current AppState
 * @param slugOrId slug or id of the searched project the user has a membership relation to
 * @returns the project the user is member with the given slug or id, or null if no project matches id or slug
 */
export const selectProjectStubBySlugOrIdFromCurrentUsersObjectRoles =
  (state: AppState, slugOrId: string | number): IProject | null => {
    if (!slugOrId) {
      return null
    }
    const myMemberships = selectMyMemberships(state)

    let membership: IUserProjectRole = null

    // is slugOrId convertable to a number: than the id is already given
    const id = stringToInt(slugOrId as string)
    // if the id is not given search for the slug
    if (id) {
      membership = myMemberships.find(ms => ms.object.id === id)
    } else {
      membership = myMemberships.find(ms => ms.object.slug === slugOrId)
    }

    // assume: membership.project is not an IRI but a project stub
    return membership?.object ?? null
  }


/**
 * Selector to retrieve the list of project IDs the user is a member of.
 *
 * @param state
 * @returns list of ids of users membership projects
 */
export const selectMyProjectIDs = (state: AppState): number[] => {
  const memberships = selectMyMemberships(state)

  return memberships.map((membership) => idFromIModelOrIRI(membership.object))
}