import { IProposalContent, ObligationState } from "@api/schema"

/**
 * This ContentManagementHelper-component provides functions to handle ContentItems.
 *
 * These functions are provided to let the ChallengeManager (or other) chose from a given list of content-fields, that belong
 * to a project: which of those content-fields should be printed in the PDF of the proposal?
 *
 * There are three possible options, defined in schema:ObligationState:
 * Required: The content must be provided by the project team to have a chance of support
 * Optional: The team is free in providing this content, but it will not effect the proposal in relevant form
 * Notwanted: The content will not be put into the proposal-PDF.
 *
 * ContentItems are also used in the Feedback-System to specify, what project-content-element a user is commenting on.
 * They also may be used to lock form-elements in collaborating-situation (via the RTC-system), if one user wants to edit
 * the same project or the same content in a project as another user is doing at the same time.
 */


/**
 * Defines the type for an array of ContentItems
 */
export type ContentItem = {
  [key in AvailableContentItems]: {
    printable: boolean // is it printable to the PDF?
    printedByDefault?: boolean // is it printed by default?
    explanation?: string // option to explain, why the setting is how it is, e.g: project.impact is not printable, because it is printed via project.impactAnalysis
  }
}

/**
 * Available content items are a combination of content items for specific types, e.g. project- + applications-contents
 */
export type AvailableContentItems =
  (AvailableProjectContentItems | AvailableProposalContentItems)
  & (AvailableProjectContentItems | AvailableProposalContentItems | AvailableFundApplicationContentItems)

/**
 * available Content-Items for projects
 */
export enum AvailableProjectContentItems {
  // single elements for project-specifics
  ProjectName = "project.name",
  SelfAssessmentProfile = "project.selfassessment.profile",
  ShortDescription = "project.shortDescription",
  Description = "project.description",
  DescriptionExtension = "project.descriptionExtension",
  Challenges = "project.challenges",
  Goals = "project.goals",
  Vision = "project.vision",
  Categories = "project.categories",
  SDGs = "project.sdgs",
  Delimitation = "project.delimitation",
  ImplementationLocations = "project.implementationLocations",
  Map = "project.map",
  Picture = "project.picture",
  Visualization = "project.visualization",
  TargetGroups = "project.targetGroups",
  ExpectedInvolvedPersons = "project.expectedInvolvedPersons",
  Impact = "project.impact",
  Results = "project.results",
  Outcome = "project.outcome",
  Utilization = "project.utilization",
  Timetable = "project.timetable",
  OwnContributions = "project.ownContributions",
  Partners = "project.partners",
  // standing data
  BankDetails = "project.bankDetails",
  Holder = "project.holder",
  Contact = "project.contact",

  // explanations
  GoalExplanation = "project.goalExplanation",
  SDGExplanation = "project.sdgExplanation",

  // complex elements, @todo: should they be organised in another enum?
  CostFinancePlan = "project.costFinancePlan",
  ImpactAnalysis = "project.impactAnalysis",
  ResourcePlan = "project.resourcePlan",
  Workplan = "project.workPlan",
}

/**
 * available Content-Items for (challenge) proposals
 */
export enum AvailableProposalContentItems {
  // for Proposal-specifics
  ProposalAttachments = "proposal.attachments",
  ProposalConcretizations = "proposal.concretizations",
}

/**
 * available Content-Items for fund applications
 */
export enum AvailableFundApplicationContentItems {
  ProposalThirdPartyFundsExplanation = "proposal.thirdPartyFundsExplanation",
}

/**
 * default settings for available ContentItems (proposal for a challenge)
 */
export const DefaultProposalPDFContentSettings: ContentItem = {
  // project-specific:
  "project.name": {
    printable: true,
    printedByDefault: true
  },
  "project.selfassessment.profile": {
    printable: false
  },
  "project.shortDescription": {
    printable: true,
    printedByDefault: true
  },
  "project.description": {
    printable: true,
    printedByDefault: true
  },
  "project.descriptionExtension": {
    printable: true,
    printedByDefault: false
  },
  "project.challenges": {
    printable: true,
    printedByDefault: true
  },
  "project.goals": {
    printable: true,
    printedByDefault: true
  },
  "project.vision": {
    printable: true,
    printedByDefault: true
  },
  "project.categories": {
    printable: true
  },
  "project.sdgs": {
    printable: true,
    printedByDefault: true
  },
  "project.delimitation": {
    printable: true,
    printedByDefault: true
  },
  "project.implementationLocations": {
    printable: true,
    printedByDefault: true
  },
  "project.map": {
    printable: false
  },
  "project.picture": {
    printable: true,
    printedByDefault: true
  },
  "project.visualization": {
    printable: true,
    printedByDefault: true
  },
  "project.targetGroups": {
    printable: true,
    printedByDefault: true
  },
  "project.expectedInvolvedPersons": {
    printable: true,
    printedByDefault: false
  },
  "project.impact": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.results": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.outcome": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.utilization": {
    printable: true,
    printedByDefault: false
  },
  "project.timetable": {
    printable: true,
    printedByDefault: true
  },
  "project.ownContributions": {
    printable: true,
    printedByDefault: true
  },
  "project.partners": {
    printable: true,
    printedByDefault: false
  },
  "project.bankDetails": {
    printable: true,
    printedByDefault: true
  },
  "project.holder": {
    printable: true,
    printedByDefault: true
  },
  "project.contact": {
    printable: true,
    printedByDefault: true
  },

  // explanations
  "project.goalExplanation": {
    printable: true,
    printedByDefault: false
  },
  "project.sdgExplanation": {
    printable: true,
    printedByDefault: false
  },

  // complex elements
  "project.costFinancePlan": {
    printable: true,
    printedByDefault: true
  },
  "project.impactAnalysis": {
    printable: true,
    printedByDefault: false
  },
  "project.resourcePlan": {
    printable: true,
    printedByDefault: true
  },
  "project.workPlan": {
    printable: true,
    printedByDefault: true
  },


  // proposal-specific:
  "proposal.attachments": {
    printable: true,
    printedByDefault: true
  },
  "proposal.concretizations": {
    printable: true,
    printedByDefault: true
  },
  /* "proposal.thirdPartyFundsExplanation": {
      printable: true,
      printedByDefault: false
  },*/
}

/** current used version for IProposalContent */
export const iProposalContentVersion = 2

/**
 * returns if a given key in an array of given IProposalContent is required
 */
export const contentForProposalRequiredByChallenge = (contentKey: AvailableProjectContentItems | AvailableProposalContentItems, proposalContents: IProposalContent[]): boolean => {
  return proposalContents?.find(k => k.contentId === contentKey && k.requirement === ObligationState.Required) !== undefined
}

/**
 * Creates an array of IProposalContent from given contentItems
 */
export const createIProposalContentFromContentItem = (contentItems?: ContentItem): IProposalContent[] => {
  // use given list of ContentItem or the default
  const usedContent = contentItems || DefaultProposalPDFContentSettings
  const resultingProposalContent: IProposalContent[] = []
  Object.keys(usedContent).map((key: AvailableContentItems) => {
    if (usedContent[key].printable === true && usedContent[key].printedByDefault === true) {
      resultingProposalContent.push(createIProposalContent(key, ObligationState.Required))
    }
  })
  return resultingProposalContent
}

/**
 * creates a fresh IProposalContent with current version
 *
 * @param contentId a valid content id
 * @param requirement a valid ObligationState
 * @returns a valid IProposalContent
 */
export const createIProposalContent = (contentId: AvailableContentItems, requirement: ObligationState): IProposalContent => {
  return {
    contentId,
    requirement,
    version: iProposalContentVersion
  }
}