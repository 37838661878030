import { Action } from "redux"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IProject, ProjectState } from "@api/schema"
import { IFormikActions, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType } from "@redux/reduxTypes"
import { SubmissionError } from "@services/submissionError"


/** *************************************************************************************************
 * This enum defines the usecases around the "project state".
 */
enum ProjectStatesUsecases {
  /**
   * toggle the state of a project
   */
  ToggleStateProject = "_usecase_toggle_state_project",
}

interface IToggleProjectStateAction extends Action {
  actions: IFormikActions
  project: IProject
  type: ProjectStatesUsecases.ToggleStateProject
}

/**
 * Creates an action to toggles the project state.
 *
 * If the project state is inactive or deactivated, an api request will be send to activate the project,
 * otherwise an api request will be send to deactivate the project.
 */
export const toggleProjectStateAction = (project: IProject, actions: IFormikActions): IToggleProjectStateAction => ({
  actions,
  project,
  type: ProjectStatesUsecases.ToggleStateProject,
})

export function* projectStateWatcherSaga(): any {
  yield all([
    takeEvery(ProjectStatesUsecases.ToggleStateProject, withCallback(projectStateSaga)),
  ])
}

/**
 * General saga to change the state of a project.
 *
 * @param action to deactivate or activate a project
 * @returns changed project
 */
function* projectStateSaga(action: IToggleProjectStateAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // ProjectStatesUsecases

  let project: IProject = null
  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))
    switch (action.project.state) {
      case ProjectState.Inactive:
      case ProjectState.Deactivated:
        project = yield call(apiClient.activateProject, action.project)
        break
      case ProjectState.Active:
        project = yield call(apiClient.deactivateProject, action.project)
        break
      // ensures the switch is exhaustive
      default: action.project.state satisfies never
    }

    yield put(updateModelSuccessAction(EntityType.Project, project))

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, project))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, project)
    }

    return project
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}