import { Action } from "redux-saga"

/**
 * This is a copy of IRequestState in /src/redux/helper/state.ts
 */
export interface IRequestState {
  /** is the request currently loading? */
  isLoading: boolean
  /** error message if an error occured while the request */
  loadingError: string
  /** was the request finished after it was started? */
  loaded: boolean
}

/**
 * Definitions of all "request scopes" that should be "trackable".
 */
export enum OIDCRequestScopes {
  LoadOIDCProviders = "oidc_fetch_providers",
  FetchOIDCToken = "oidc_load_token",
  LoginWithOIDCToken = "oidc_login_with_token"
}

/**
 * A selector for `IRequestState`s for each `OIDCRequestScopes`
 */
export type OIDCRequestStateSelector = (scope: OIDCRequestScopes) => (state: any) => IRequestState

/**
 * Generator for "request tracking" actions to be dispatched.
 */
export interface IRequestStateAPI {
  /** be called when a request starts */
  taskStartedAction: (scope: OIDCRequestScopes) => Action
  /** to be called when a request succeeded successfully */
  taskSucceededAction: (scope: OIDCRequestScopes) => Action
  /** to be called when a request failed */
  taskFailedAction: (scope: OIDCRequestScopes, error: string) => Action
  /**
   * to be called, when a task consists of more than one request to merge the requests
   * for more easy evaluation
   */
  mergeRequestStates: (...requests: IRequestState[]) => IRequestState
  /** used to get the current request state from the app state */
  selectOIDCRequestState: OIDCRequestStateSelector
}
