import Image from 'next/image'
import Link from "next/link"
import { Col, Row } from "reactstrap"


import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { SINN_BASE_URL } from '@customer-config'
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { hashIdContactPageBugReport } from "@services/processHelper"
import { Routes } from "@services/routes"
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config"

import LanguageSelector from "./Footer/LanguageSelector"
import SocialLinks from "./SocialLinks"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  isFrontPage?: boolean
}

/**
 * This component provides the page-footer of the client-application, used by BaseLayout
 */
const Footer: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()

  return (
    <footer>
      <Row className="footer-content">
        <Col className="footer__side-cols">
          {props.isFrontPage && <SocialLinks />}
        </Col>
        <Col className="footer-links">
          <Link href={`${SINN_BASE_URL}/nutzungsbedingungen`}>
            {t("base-layout", "termsOfUse")}
          </Link>{" "}
          |{" "}
          <Link href={Routes.Imprint}>
            {t("base-layout", "imprint")}
          </Link>{" "}
          |{" "}
          <Link href={`${SINN_BASE_URL}/ueber-uns/newsletter`}>
            {t("base-layout", "newsletter")}
          </Link>{" "}
          |{" "}
          <Link href={Routes.DataProtection}>
            {t("base-layout", "dataProtection")}
          </Link>{" "}
          |{" "}
          <Link href={`${SINN_BASE_URL}/cookies`}>
            {t("base-layout", "cookies")}
          </Link>{" "}
          |{" "}
          <Link href={`${SINN_BASE_URL}/barrierefreiheitserklaerung`}>
            {t("base-layout", "accessibilityExplanation")}
          </Link>{" "}
          |{" "}
          {!SINN_PROTOTYPE_CLIENT_CONFIG_USED && <>
            <Link href={Routes.Registration}>
              {t("base-layout", "register")}
            </Link>{" "}
            | {" "}
          </>}
          < Link href={Routes.Contact + hashIdContactPageBugReport}>
            {t("base-layout", "bugReport")}
          </Link>
        </Col>
        <Col className="footer__side-cols">
          <LanguageSelector />
        </Col>
      </Row>
      <div className="sponsor-logos">
        <Image
          src="/assets/img/sinn-sachsen/EU-Logo.png"
          alt="Sponsored by EU"
          width="350"
          height="76"
          priority
        />
      </div>
    </footer>
  )
}

export default withDynamicNamespaces<IProps>(Footer, usedNamespaces)
