import apiClient from "@api/client"
import { IAuthReply, INativeHydraCollection } from "@api/schema"
import { usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { mergeRequestStates } from "@redux/helper/state"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { selectOIDCState } from "@redux/reducer"
import { selectOIDCRequestState } from "@redux/reducer/requests"
import { setStorageItem, getStorageItem, removeStorageItem } from "@services/localStorage"
import { Routes } from "@services/routes"
import { BASE_URL } from "config"

import { initializeOIDCModule, IOIDCProvider } from "./oidc/src"
import { OIDCRequestScopes } from "./oidc/src/models/request-states"

/**
 * Initial function to initiate the OICD module,
 * @returns
 */
const initOIDC = () => initializeOIDCModule<IAuthReply, INativeHydraCollection<IOIDCProvider>>({
  oidcAPI: apiClient,
  requestStateAPI: {
    taskStartedAction: (scope: OIDCRequestScopes) => usecaseRequestRunningAction(scope),
    taskSucceededAction: (scope: OIDCRequestScopes) => usecaseRequestSuccessAction(scope, true),
    taskFailedAction: (scope: OIDCRequestScopes, error: string) => usecaseRequestRunningAction(scope, error),
    mergeRequestStates,
    selectOIDCRequestState
  },
  selectOIDCState,
  storageAPI: {
    setStorageItem,
    getStorageItem,
    removeStorageItem
  },
  redirectURL: BASE_URL + Routes.AccountVerification,
  routeTo: (url: string) => { window.location.href = url },
  handleSagaError: showErrorsInTestEnvironment,
  getUnknownErrorCode: () => UNKNOWN_REQUEST_ERROR
})

export const initializeModules = () => {
  initOIDC()
}