import { configureStore } from '@reduxjs/toolkit'
import { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios"
import { useMemo } from "react"
import { Action, Store } from "redux"
import createSagaMiddleware from "redux-saga"

import apiClient from "@api/client"
import { rootReducer, AppState } from "@redux//reducer"
import { selectAuthToken } from "@redux//reducer/auth"
import { rootSaga } from "@redux//saga"
import { hydrateAction } from "@redux/actions/platform"
import { runsInBrowser } from '@services/util'

/**
 * Creates a new Redux store, created server-side but not really used, no sagas run
 */
export const makeStore = (): Store<AppState, Action> => {
  // create the stdChannel (saga middleware)
  const sagaMiddleware = createSagaMiddleware()

  // Automatically adds the thunk middleware and the Redux DevTools extension
  const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        // redux/toolkit recommends not to use non-serializable data in actions:
        // https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
        // so it throws errors when Actions carry non-serializable data.
        // in our case we transfer formikActions from formik forms e.g. to call onSuccess after successfull saving
        // the form data and we write getItems() functions into the filteredCollection REQUEST states
        // see https://futureprojects.atlassian.net/browse/FCP-1542
        //
        // for configuration: @see https://redux-toolkit.js.org/api/serializabilityMiddleware
        serializableCheck: {
          // no warnings when non-serializable data is put into state
          ignoreState: true,
          // no warnings when non-serializable data is put into actions
          ignoreActions: true,
        }
      }).concat(sagaMiddleware)

      // Conditionally add another middleware in dev/test
      // if (process.env.NODE_ENV === 'test') {
      //   middleware.push(logger)
      // }

      return middleware
    },
  })

  if (runsInBrowser()) {
    // sagas should run only in the client
    sagaMiddleware.run(rootSaga)

    /**
     * Connect store and the apiClient to inject the JWT
     *
     * @todo is there a better place for this?
     */
    apiClient.axios.interceptors.request.use((request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      // these endpoints do not work when an (expired) token is present in the headers
      if (request.url === "/authentication_token" || request.url === "/refresh_token") {
        return request
      }

      const token = selectAuthToken(store.getState())
      if (!token) {
        return request
      }

      request.headers = request.headers || {} as AxiosRequestHeaders
      request.headers.Authorization = `Bearer ${token}`

      return request
    })
  }

  return store
}

/**
 * useMemo ensures the store is not recreated on each page change in the client.
 *
 * @param initialState may be used by pages with getServerSideProps to inject some state
 */
export const useStore = (initialState: AppState): Store<AppState> => {
  const store = useMemo(() => makeStore(), [initialState])
  if (initialState) {
    store.dispatch(hydrateAction(initialState))
  }

  return store
}